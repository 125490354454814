<template>
  <div class="maindiv">
    <el-card class="box-card">
      <div :class="TYPE === 'PC' ? 'PCtitle' : 'MBtitle'">
        <div>Please fill out the form to help us locate the problem:</div>

        <div>
          <el-input
            placeholder="Please enter the query code"
            v-model="queryCode"
            class="input-with-select"
          >
            <el-button
              @click="getonebycode(queryCode)"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </div>
      </div>
    </el-card>
    <el-card class="box-card">
      <div class="formparents">
        <el-form
          :model="subform"
          :rules="rules"
          ref="subform"
          label-width="200px"
          class="demo-ruleForm"
          :label-position="TYPE === 'PC' ? 'left' : 'top'"
        >
          <el-form-item label="Purchase From" prop="channel">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.channel"
            ></el-input>
          </el-form-item>

          <el-form-item label="Order Number">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.orderNum"
            ></el-input>
          </el-form-item>

          <el-form-item label="Email" prop="email">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.email"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="Other Valid Contact Info."
            prop="otherContactInfo"
          >
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.otherContactInfo"
            ></el-input>
          </el-form-item>

          <el-form-item label="Country" prop="country">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.country"
            ></el-input>
          </el-form-item>

          <el-form-item label="Device Model" prop="deviceModel">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.deviceModel"
            ></el-input>
          </el-form-item>

          <el-form-item label="IMEI" prop="imei">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.imei"
            ></el-input>
          </el-form-item>

          <el-form-item label="ICCID" prop="iccid">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.iccid"
            ></el-input>
          </el-form-item>

          <el-form-item label="Platform/APP" prop="platform">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.platform"
            ></el-input>
          </el-form-item>

          <el-form-item label="Problem Type" prop="type">
            <el-select
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.type"
              placeholder="Please select your problem type"
            >
              <el-option label="Device Inactive" value="0"></el-option>
              <el-option label="Device Offline" value="1"></el-option>
              <el-option label="IMEI Not Existed" value="2"></el-option>
              <el-option
                label="I already paid, still not working"
                value="3"
              ></el-option>
              <el-option label="Wrong location" value="4"></el-option>
              <el-option label="About Alert" value="5"></el-option>
              <el-option label="About Subscription" value="6"></el-option>
              <el-option label="About Battery / Charging" value="7"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Problem Description" prop="description">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              type="textarea"
              :autosize="{ minRows: 5 }"
              v-model="subform.description"
            ></el-input>
          </el-form-item>

          <el-form-item label="Device LED Light Status" prop="ledStatus">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.ledStatus"
            ></el-input>
          </el-form-item>

          <el-form-item label="Network Operator" prop="simCardOperator">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.simCardOperator"
            ></el-input>
          </el-form-item>

          <el-form-item label="Usage Scenario" prop="usageScenario">
            <el-select
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.usageScenario"
              placeholder="Please select your usage scenario"
            >
              <el-option label="Vehicle" value="0"></el-option>
              <el-option label="Personal" value="1"></el-option>
              <el-option label="Asset" value="2"></el-option>
              <el-option label="Other" value="3"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="Installed Vehicle Model"
            prop="installedVehicleModel"
          >
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.installedVehicleModel"
            ></el-input>
          </el-form-item>

          <el-form-item label="Device Status" prop="deviceStatus">
            <el-input
              :class="TYPE === 'PC' ? 'PCitemClass' : 'MBitemClass'"
              v-model="subform.deviceStatus"
            ></el-input>
          </el-form-item>

          <el-form-item label="Image Upload">
            <el-col :span="22">
              <el-upload
                action="/api/ecommerce/e-commerce-client-work-order/uploadImg"
                accept=".jpg,.png"
                :limit="5"
                multiple
                list-type="picture"
                :file-list="imageList"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleImageRemove"
                :on-success="handleImgUploadSuccess"
              >
                <el-button size="mini" type="primary"
                  >Select image (only jpg or png )</el-button
                >
              </el-upload>
            </el-col>
          </el-form-item>

          <el-form-item label="Attachment Upload">
            <el-col :span="22">
              <el-upload
                action="/api/ecommerce/e-commerce-client-work-order/uploadFile"
                :limit="5"
                :file-list="fileList"
                :on-remove="handleRemove"
                :on-success="handleUploadSuccess"
              >
                <el-button size="mini" type="primary"
                  >Click to upload</el-button
                >
              </el-upload>
            </el-col>
          </el-form-item>

          <div>
            <el-button type="primary" :disabled="cando" @click="submitForm()"
              >Submit</el-button
            >
            <el-button @click="resetForm('subform')">Resetting</el-button>
          </div>
        </el-form>
      </div>
    </el-card>

    <!-- 预览图片 -->
    <el-dialog
      modal-append-to-body
      :modal="false"
      :visible.sync="dialogVisible"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <!-- 成功弹窗 -->
    <el-dialog
      title="success"
      modal-append-to-body
      :modal="false"
      :visible.sync="showsuccess"
    >
      {{ successdata }}
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showsuccess = false">close</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Work Order Details:"
      modal-append-to-body
      :modal="false"
      :visible.sync="detailVisible"
    >
      <el-steps :active="datiledata.status + 1" finish-status="success">
        <el-step title="committed"></el-step>
        <el-step title="processing"></el-step>
        <el-step title="completed"></el-step>
      </el-steps>

      <el-descriptions direction="vertical" :column="16" border>
        <el-descriptions-item label="Purchase From" :span="4">
          {{ datiledata.channel }}
        </el-descriptions-item>
        <el-descriptions-item label="Device Status" :span="3">{{
          datiledata.deviceStatus
        }}</el-descriptions-item>

        <el-descriptions-item label="Order Number" :span="3">{{
          datiledata.orderNum
        }}</el-descriptions-item>

        <el-descriptions-item label="Email" :span="3">{{
          datiledata.email
        }}</el-descriptions-item>

        <el-descriptions-item label="Other Valid Contact Info." :span="6">{{
          datiledata.otherContactInfo
        }}</el-descriptions-item>

        <el-descriptions-item label="Country" :span="3">{{
          datiledata.country
        }}</el-descriptions-item>

        <el-descriptions-item label="IMEI" :span="3">{{
          datiledata.imei
        }}</el-descriptions-item>

        <el-descriptions-item label="Platform/APP" :span="5">{{
          datiledata.platform
        }}</el-descriptions-item>

        <el-descriptions-item label="Problem Type" :span="5">
          {{ datiledata.type }}
        </el-descriptions-item>

        <el-descriptions-item label="Problem Description" :span="16">{{
          datiledata.description
        }}</el-descriptions-item>

        <el-descriptions-item label="Device LED Light Status" :span="4">{{
          datiledata.ledStatus
        }}</el-descriptions-item>

        <el-descriptions-item label="Network Operator" :span="4">{{
          datiledata.simCardOperator
        }}</el-descriptions-item>

        <el-descriptions-item label="Usage Scenario" :span="4">
          {{ datiledata.usageScenario }}
        </el-descriptions-item>

        <el-descriptions-item label="Installed Vehicle Model" :span="4">{{
          datiledata.installedVehicleModel
        }}</el-descriptions-item>

        <el-descriptions-item label="Image" :span="16">
          <el-image
            v-for="{ url } in datiledata.image"
            :key="url"
            :src="url"
            :previewSrcList="KFpreviewList"
          />
        </el-descriptions-item>
        <el-descriptions-item label="Attachment" :span="16">
          <p v-for="{ url, path } in datiledata.attachment" :key="url">
            <a :href="url" target="_blank">{{ path }}</a>
          </p>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailVisible = false"
          >close</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { AddcusOrderUrl, getByQueryCode } from "@/api/custom";
export default {
  mounted() {
    if (this.isMobile()) {
      console.log("这是移动端");
      this.TYPE = "MB";
    } else {
      console.log("这是PC端");
      this.TYPE = "PC";
    }
  },
  created() {
    this.queryCode = this.$route.query.queryCode;
    this.encryptFlag = this.$route.query.encryptFlag;
    if (this.$route.query.queryCode) {
      this.getonebycode(
        this.$route.query.queryCode,
        this.$route.query.encryptFlag
      );
    }
  },
  name: "Home",
  components: {},
  data() {
    return {
      TYPE: null,
      showsuccess: false,
      successdata: null,
      queryCode: null,
      encryptFlag: false,
      cando: false,
      imageList: [],
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      detailVisible: false,
      datiledata: {},
      subform: {
        channel: null,
        email: null,
        otherContactInfo: null,
        country: null,
        imei: null,
        platform: null,
        type: null,
        description: null,
        ledStatus: null,
        simCardOperator: null,
        usageScenario: null,
        installedVehicleModel: null,
        firmwareVersion: null,
        deviceStatus: null,
      },

      rules: {
        channel: [
          {
            required: true,
            message: "Please select your purchasing from",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "Please enter your country",
            trigger: "blur",
          },
        ],

        imei: [
          {
            required: true,
            message: "Please enter your imei",
            trigger: "blur",
          },
        ],

        deviceModel: [
          {
            required: true,
            message: "Please enter your device model",
            trigger: "blur",
          },
        ],

        platform: [
          {
            required: true,
            message: "Please enter your platform",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Please select your Problem Type",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Please enter your problem description",
            trigger: "blur",
          },
        ],
        ledStatus: [
          {
            required: true,
            message: "Please enter your problem ledStatus",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    async getonebycode(queryCode, encryptFlag) {
      try {
        const res = await getByQueryCode({
          queryCode: queryCode,
          encryptFlag: encryptFlag,
        });
        if (res.data.code === 200) {
          this.datiledata = res.data.data;

          if (this.datiledata.type == 0) {
            this.datiledata.type = "Device Inactive";
          } else if (this.datiledata.type == 1) {
            this.datiledata.type = "Device Offline";
          } else if (this.datiledata.type == 2) {
            this.datiledata.type = "IMEI Not Existed";
          } else if (this.datiledata.type == 3) {
            this.datiledata.type = "I already paid, still not working";
          } else if (this.datiledata.type == 4) {
            this.datiledata.type = "Wrong location";
          } else if (this.datiledata.type == 5) {
            this.datiledata.type = "About Alert";
          } else if (this.datiledata.type == 6) {
            this.datiledata.type = "About Subscription";
          } else if (this.datiledata.type == 7) {
            this.datiledata.type = "About Battery / Charging";
          }

          if (this.datiledata.usageScenario == 0) {
            this.datiledata.usageScenario = "Vehicle";
          } else if (this.datiledata.usageScenario == 1) {
            this.datiledata.usageScenario = "Personal";
          } else if (this.datiledata.usageScenario == 2) {
            this.datiledata.usageScenario = "Asset";
          } else if (this.datiledata.usageScenario == 3) {
            this.datiledata.usageScenario = "Other";
          }

          this.detailVisible = true;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.encryptFlag = false;
      }
    },
    async submitForm() {
      this.cando = true;
      try {
        const res = await AddcusOrderUrl({
          ...this.subform,
          attachment: this.fileList.map(({ path }) => path).join(","),
          image: this.imageList.map(({ path }) => path).join(","),
        });
        if (res.data.code === 200) {
          this.successdata =
            "Submitted successfully,You can use " +
            res.data.data +
            " to query this work order";
          this.showsuccess = true;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.cando = false;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleImageRemove(file, imageList) {
      this.imageList = imageList;
    },
    /* 预览 */
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleImgUploadSuccess(response, image) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.imageList = [...this.imageList, { ...image, url, path }];
      } else {
        this.imageList = [...this.imageList];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleUploadSuccess(response, file) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.fileList = [...this.fileList, { ...file, url, path }];
      } else {
        this.fileList = [...this.fileList];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.maindiv {
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #d8dcdf;
  display: flex;
  flex-direction: column;
}
.c1 {
}
.c2 {
}
.box-card {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 3px 3px 3px #888888;
  border-radius: 5px;
}
.formparents {
  display: flex;
  justify-content: center;
}
.PCtitle {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.MBtitle {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.PCitemClass {
  width: 400px;
}
.MBitemClass {
}

/deep/.el-descriptions-item__label {
  background: #77d5f4;
  font-weight: bolder;
  color: black;
}
</style>
