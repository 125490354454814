import request from "../utils/requst"

export const AddcusOrderUrl = data =>{
    return request({
        method:'POST',
        url:'ecommerce/e-commerce-client-work-order/add',
        data,
    })
}

export const getByQueryCode = params =>{
    return request({
        method:'GET',
        url:'ecommerce/e-commerce-client-work-order/getByQueryCode',
        params,
    })
}



// export const FaeExportExlUrl = params =>{
//     return request({
//         method:'GET',
//         url:'procedure/technical-support-work-order/export',
//         params,
//         responseType: 'blob'
//     })
// }

// export const getyanfatimeline = params =>{
//     return request({
//         method:'GET',
//         url:'procedure/technical-support-work-order/development/get',
//         params,
//     })
// }
// export const AddFaeOrderUrl = data =>{
//     return request({
//         method:'POST',
//         url:'procedure/technical-support-work-order/add',
//         data,
//     })
// }







