import axios from "axios";
import router from '../router'

// let url = '';
// if(process.env.NODE_ENV === 'development'){
//     url = '172.26.135.122:8099/api'
// } 
// if(process.env.NODE_ENV === 'production'){
//     url = '172.26.135.122:8099/api'
// } 
const request = axios.create({
    baseURL: "/api",
    headers: {
        'Content-Type': 'application/json',
    },
})

request.interceptors.request.use(
    function (config) {
        const usertoken = JSON.parse(window.localStorage.getItem("usertoken"))
        if (usertoken) {
            config.headers.Authorization = usertoken
        }
        if (config.method === 'delete' || config.method === 'get'
        ) {
            const params = {}
            for (let i in config.params) {
                if (Array.isArray(config.params[i])) {
                    params[i] = config.params[i].join(',')
                } else params[i] = config.params[i]
            }
            config.params = params
        } else if (
            !config.headers['Content-Type'].includes('application/json')
            && typeof config.data === 'object'
            && !(config.data instanceof FormData)) {
            /* object 转成 formData */
            const data = new FormData()
            for (let i in config.data) { data.append(i, config.data[i]) }
            config.data = data
        }
        return config
    },
    function (error) {
        return Promise.reject(error);
    }
)
// request.interceptors.response.use(function (res) {
//     // 在发送请求之前可以做一些事情
//     if (res.data.code === 401) {
//         router.push({
//             name: "Login",
//         });
//         return res
//     } else {
//         return res;
//     }
// }, function (error) {
//     console.log('请求错误： ', error)
//     // 处理请求错误
//     return Promise.reject(error)


// });




export default request